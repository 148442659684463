
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { setTitle, handle429 } from '@util/util.ts';
import { app } from '@config';
import ButtonGroup from '../../../fruitpackage/components/ButtonGroup.vue';
import Slider from '../../../fruitpackage/components/Slider.vue';
import CopyUrl from '@part/elements/CopyUrl.vue';
import CardDefault from '@part/elements/CardDefault.vue';
import CardLong from '@part/elements/CardLong.vue';
import MyNightButton from '@part/elements/MyNightButton.vue';
import InTheHood from '@part/elements/InTheHood.vue';
import ImageTextBlock from '@part/blocks/ImageTextBlock.vue';
import Overlay from '@part/layout/Overlay.vue';
import CrossCircleSvg from '@svg/CrossCircle.vue';
import ArrowBackSvg from '@svg/ArrowBack.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import RefreshSvg from '@svg/Refresh.vue';
import ExternalSvg from '@svg/External.vue';
import FacebookSvg from '@svg/Facebook.vue';
import InstagramSvg from '@svg/Instagram.vue';
import TiktokSvg from '@svg/Tiktok.vue';
import PinGhostSvg from '@svg/PinGhost.vue';
import ClockSvg from '@svg/Clock.vue';
import InfoSvg from '@svg/Info.vue';
import axios from 'axios';
import DetailLayout from '@part/elements/DetailLayout.vue';
import AmbassadorsCard from '@part/elements/AmbassadorsCard.vue';

// import { mapState } from 'vuex';
// import { AppStore } from '@interface/store/App';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    CardDefault,
    CardLong,
    CopyUrl,
    MyNightButton,
    InTheHood,
    ImageTextBlock,
    ButtonGroup,
    Slider,
    Overlay,
    ArrowBackSvg,
    ArrowForwardSvg,
    CrossCircleSvg,
    RefreshSvg,
    ExternalSvg,
    FacebookSvg,
    InstagramSvg,
    TiktokSvg,
    PinGhostSvg,
    ClockSvg,
    InfoSvg,
    DetailLayout,
    AmbassadorsCard
  },
  data() {
    return {
      pageData: null,
      infoOverlayVisible: false,
      exhibitionOverlayVisible: false,
      activeInfo: { info: '', title: '' },
      activeExhibition: { image: '', text: '', title: '', url: '' },
      museumsInTheHood: [],
      backLink: {
        text: 'Tentoonstellingen',
        url: '/tentoonstellingen'
      }
    };
  },
  props: {
    id: {
      required: true,
      default: 0
    }
  },
  computed: {
    shuffledEvents() {
      return this.shuffleArr(this.pageData.events);
    }
  },
  methods: {
    closeOverlay() {
      this.infoOverlayVisible = false;
      this.exhibitionOverlayVisible = false;
    },
    fixUrl(url) {
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, '');
      if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
      }
      return newUrl;
    },
    infoOverlay(info, title, subtitle) {
      this.activeInfo = { info: info, title: title, subtitle: subtitle };
      this.infoOverlayVisible = true;
    },
    exhibitionOverlay(data) {
      this.activeExhibition = data;
      this.exhibitionOverlayVisible = true;
    },
    shuffleArr(a) {
      let j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      a > 10 && a.slice(0, 10);
      return a;
    }
  },
  mounted() {
    let $this = this;
    axios
      .get(`${app.api.api}/exhibition/${$this.id}`)
      .then(response => {
        setTitle(response);
        $this.pageData = response.data.data;
        if (response.data.data.location) {
          axios.get(`${app.api.api}/feed/exhibitions/${$this.pageData.location.id}`).then(resp => {
            $this.museumsInTheHood = resp.data.data;
          });
        }
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class ExhibitionDetail extends Vue { }
